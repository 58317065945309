import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import SkeletonContainer from '~/components/Skeleton'
import { CircleCheckFill } from '~/core/ui/FillIcons'
import { ScrollArea } from '~/core/ui/ScrollArea'
import { Skeleton } from '~/core/ui/Skeleton'
import { TabsContent } from '~/core/ui/Tabs'
import { TypographyText } from '~/core/ui/Text'
import { cn } from '~/core/ui/utils'
import { MatchedField, MatchedFieldTotal } from '~/lib/features/jobs/types'

type MatchedFieldExtraAttr = {
  helpText?: string
}

type MatchedFieldType = MatchedField & MatchedFieldExtraAttr

const TabContent: FC<{
  tabValue: string
  isLoading: boolean
  data:
    | {
        detail: MatchedFieldType[]
        total: MatchedFieldTotal
      }
    | undefined
}> = ({ tabValue, data, isLoading }) => {
  const { t } = useTranslation()
  return (
    <TabsContent value={tabValue} className="mt-4">
      <ScrollArea viewportClassName="h-full w-full rounded-[inherit] [&>*:first-child]:!block max-h-[calc(100vh_-_315px)] flex-1 overflow-y-auto">
        <SkeletonContainer
          showMoreLabel={`${t('common:infinity:showMore')}`}
          useLoading={false}
          isFirstLoading={isLoading}
          classNameFirstLoading="mt-3"
          renderCustomSkeleton={
            <>
              {[1, 2, 3].map((item) => (
                <div
                  key={`task-skeleton-${item}`}
                  className="space-y-1.5 rounded-md bg-white">
                  <Skeleton className="h-4 w-full rounded" />
                  <Skeleton className="h-2 w-1/2 rounded" />
                </div>
              ))}
            </>
          }>
          <div className="grid gap-y-5 px-6">
            {data?.detail.map(
              (matchedField: MatchedFieldType, index: number) => (
                <div key={`matched-field-detail-${index}`}>
                  <div className="mb-1.5 flex items-center gap-x-1.5">
                    <TypographyText className="text-sm font-medium text-gray-900">
                      {matchedField?.label}
                    </TypographyText>
                    {matchedField?.helpText && (
                      <TypographyText className="text-sm text-gray-600">
                        ({matchedField?.helpText})
                      </TypographyText>
                    )}
                    {matchedField?.matched_rate && (
                      <TypographyText className="text-sm text-gray-600">
                        ({matchedField?.matched_rate})
                      </TypographyText>
                    )}
                  </div>
                  <div
                    className={cn(
                      'grid gap-x-4 gap-y-1.5',
                      matchedField?.data?.length > 3 ? 'grid-cols-2' : ''
                    )}>
                    {matchedField?.data?.map((field: any, index: number) => (
                      <div
                        key={`field-${index}`}
                        className="flex items-center gap-x-1.5">
                        {field.matched ? (
                          <CircleCheckFill
                            className={`flex-none !fill-green-500`}
                            size={20}
                          />
                        ) : (
                          <div className="mx-0.5 h-4 w-4 flex-none rounded-lg border border-gray-300" />
                        )}
                        <TypographyText className="flex-1 text-sm text-gray-700">
                          {field.label}
                        </TypographyText>
                      </div>
                    ))}
                  </div>
                </div>
              )
            )}
          </div>
        </SkeletonContainer>
      </ScrollArea>
    </TabsContent>
  )
}

export default TabContent
