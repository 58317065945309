import { gql } from 'urql'

const QueryMatchedFields = gql`
  query ($id: Int!, $recommendationTarget: String, $recommendWeight: JSON) {
    jobsShow(
      id: $id
      recommendationTarget: $recommendationTarget
      recommendWeight: $recommendWeight
    ) {
      id
      recommendationMatchedFields
    }
  }
`

export default QueryMatchedFields
