import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import pathConfiguration from 'src/configuration/path'
import ShareJobModal from '~/components/Jobs/ShareJobModal'
import useSubscriptionPlan from '~/components/Subscription/useSubscriptionPlan'
import { PUBLIC_APP_URL } from '~/core/constants/env'
import { Badge } from '~/core/ui/Badge'
import { Button } from '~/core/ui/Button'
import { Dialog } from '~/core/ui/Dialog'
import { CircleCheckFill } from '~/core/ui/FillIcons'
import { Tabs, TabsList, TabsTrigger, TabsTriggerView } from '~/core/ui/Tabs'
import { TypographyText } from '~/core/ui/Text'
import { TextButton } from '~/core/ui/TextButton'
import { cn } from '~/core/ui/utils'
import { JobType } from '~/lib/features/apply/jobId/types'
import { useQueryCareerSetting } from '~/lib/features/calendar/hooks/use-query-career-setting'
import { combineDomainCareerPage } from '~/lib/features/careers/[id]/utilities'
import useShareJobLogic from '~/lib/features/jobs/hooks/use-share-job-logic'
import {
  IJobManagementItemQuery,
  MatchedField,
  MatchedFieldTotal
} from '~/lib/features/jobs/types'
import {
  MATCHED_RANK_BADGE_COLOR,
  SYSTEM_JOB_FIELDS,
  TABS_KEY_MATCHING_CONDITION
} from '~/lib/features/jobs/utilities/enum'
import { JobDetailType } from '~/lib/features/referrals/types'
import { PLAN_FEATURE_KEYS } from '~/lib/features/settings/plans/utilities/enum'
import useReferralSetting from '~/lib/features/settings/referrals/hooks/useReferralSetting'
import useBoundStore from '~/lib/store'
import TabContent from './TabContent'
import { ScrollArea } from '~/core/ui/ScrollArea'
import { IRecommendationSetting } from '~/lib/features/recommendation-setting/type/recommendation-setting'
import QueryMatchedFields from '~/lib/features/career-hub/graphql/query-jobs-show'
import useQueryGraphQL from '../../../../../core/middleware/use-query-graphQL'

type MatchedFieldExtraAttr = {
  helpText?: string
}

type MatchedFieldType = MatchedField & MatchedFieldExtraAttr

export type ModalMatchedRankType = {
  open: boolean
  listRecommend?: Array<MatchedFieldType | MatchedFieldTotal>
  job?: IJobManagementItemQuery | JobDetailType | JobType
  modalDetail?: {
    title?: string
    description?: string | ReactNode
  }
}

const MatchedRankDetail: FC<{
  showAsTab?: boolean
  recommendWeight?: IRecommendationSetting | null
  children: (
    setModalMatchedRank?: Dispatch<SetStateAction<ModalMatchedRankType>>
  ) => ReactNode
}> = ({ showAsTab = false, recommendWeight, children }) => {
  const { t } = useTranslation()
  const { user } = useBoundStore()
  const {
    isFeatureEnabled,
    isUnLockFeature,
    data: dataTenantSettingPlan
  } = useSubscriptionPlan()
  const { dataReferral } = useReferralSetting({
    suspend:
      !dataTenantSettingPlan ||
      !isFeatureEnabled(PLAN_FEATURE_KEYS.referral) ||
      !isUnLockFeature(PLAN_FEATURE_KEYS.referral)
  })

  const [openShareModal, setOpenShareModal] = useState<boolean>(false)
  const [modalMatchedRank, setModalMatchedRank] =
    useState<ModalMatchedRankType>({
      open: false
    })
  const [currentTab, setCurrentTab] = useState<string>(
    TABS_KEY_MATCHING_CONDITION.job
  )

  const { data: careerResponseSetting, trigger: triggerCareerSetting } =
    useQueryCareerSetting({
      variables: {},
      shouldPause: true
    })

  const [matchedData, setMatchedData] = useState<{
    detail: MatchedFieldType[]
    total: MatchedFieldTotal
  }>()

  const {
    trigger: triggerMatchedFields,
    isLoading: isLoadingMatchedFields,
    data: matchedFields
  } = useQueryGraphQL({
    query: QueryMatchedFields,
    variables: {
      id: Number(modalMatchedRank.job?.id),
      recommendationTarget: currentTab,
      recommendWeight: recommendWeight
    },
    shouldPause: true
  })

  const { valueShareJobWithCondition } = useShareJobLogic()

  useEffect(() => {
    if (modalMatchedRank.open && showAsTab) {
      triggerMatchedFields()
    }
  }, [modalMatchedRank?.open, currentTab, showAsTab])

  useEffect(() => {
    setMatchedData(
      matchedFields?.jobsShow?.recommendationMatchedFields?.reduce(
        (
          result: { detail: MatchedFieldType[]; total: MatchedFieldTotal },
          matchedField: MatchedFieldType | MatchedFieldTotal
        ) => {
          return {
            ...result,
            ...(matchedField.field === 'total'
              ? {
                  total: matchedField as MatchedFieldTotal
                }
              : {
                  detail: [...result.detail, matchedField as MatchedFieldType]
                })
          }
        },
        { detail: [] as MatchedFieldType[], total: {} as MatchedFieldTotal }
      )
    )
  }, [matchedFields])

  const checkConditionShareJob = valueShareJobWithCondition({
    enablingReferral: dataReferral?.values?.enabling || false,
    enablingCareerSite:
      user.currentTenant?.careerSiteSettings?.enablingCareerSiteSetting ||
      false,
    enableJobReferral: modalMatchedRank?.job?.jobReferable || false,
    jobStatus: modalMatchedRank?.job?.status || ''
  })

  const matchedFieldsList = useMemo(() => {
    const result = showAsTab
      ? matchedFields?.jobsShow?.recommendationMatchedFields?.reduce(
          (
            result: { detail: MatchedFieldType[]; total: MatchedFieldTotal },
            matchedField: MatchedFieldType | MatchedFieldTotal
          ) => {
            return {
              ...result,
              ...(matchedField.field === 'total'
                ? {
                    total: matchedField as MatchedFieldTotal
                  }
                : {
                    detail: [...result.detail, matchedField as MatchedFieldType]
                  })
            }
          },
          { detail: [] as MatchedFieldType[], total: {} as MatchedFieldTotal }
        )
      : modalMatchedRank?.listRecommend?.reduce(
          (
            result: { detail: MatchedFieldType[]; total: MatchedFieldTotal },
            matchedField: MatchedFieldType | MatchedFieldTotal
          ) => {
            return {
              ...result,
              ...(matchedField.field === 'total'
                ? {
                    total: matchedField as MatchedFieldTotal
                  }
                : {
                    detail: [...result.detail, matchedField as MatchedFieldType]
                  })
            }
          },
          { detail: [] as MatchedFieldType[], total: {} as MatchedFieldTotal }
        )

    return result
  }, [matchedFields, modalMatchedRank?.listRecommend, showAsTab])

  const onCloseModal = useCallback(() => {
    setModalMatchedRank({
      open: false
    })
    setCurrentTab(TABS_KEY_MATCHING_CONDITION.job)
  }, [])

  return (
    <>
      {children(setModalMatchedRank)}
      <Dialog
        className="min-w-[480px]"
        childrenWrapperClassName="tablet:px-0 pb-0 px-0 tablet:pb-0"
        open={modalMatchedRank?.open}
        isPreventAutoFocusDialog
        label={
          <div className="flex items-center gap-x-2">
            <span>{modalMatchedRank?.modalDetail?.title}</span>
            <Badge
              color={
                MATCHED_RANK_BADGE_COLOR[
                  matchedFieldsList?.total?.total_rate_string || 'C'
                ]
              }
              size="md"
              classNameText="text-white font-medium">
              {matchedFieldsList?.total.total_rate_string} (
              {matchedFieldsList?.total?.total_rate || 0}%)
            </Badge>
          </div>
        }
        description={modalMatchedRank?.modalDetail?.description}
        onOpenChange={onCloseModal}>
        {showAsTab ? (
          <Tabs
            value={currentTab}
            onValueChange={(e) => {
              setCurrentTab(e)
            }}
            className="flex flex-col">
            <TabsList size="sm">
              <TabsTrigger
                classNameButton="w-[50%] justify-center	flex"
                size="sm"
                gapSize="md"
                value={TABS_KEY_MATCHING_CONDITION.job}>
                <TabsTriggerView
                  size="sm"
                  session={{
                    value: TABS_KEY_MATCHING_CONDITION.job,
                    label: `${t('job:formTitleJobDetails')}`
                  }}
                />
              </TabsTrigger>
              <TabsTrigger
                classNameButton="w-[50%] justify-center	flex"
                size="sm"
                gapSize="md"
                value={TABS_KEY_MATCHING_CONDITION.profile}>
                <TabsTriggerView
                  size="sm"
                  session={{
                    value: TABS_KEY_MATCHING_CONDITION.profile,
                    label: `${t('candidates:candidateInformation')}`
                  }}
                />
              </TabsTrigger>
            </TabsList>
            <TabContent
              tabValue={TABS_KEY_MATCHING_CONDITION.job}
              data={matchedData}
              isLoading={isLoadingMatchedFields}
            />
            <TabContent
              tabValue={TABS_KEY_MATCHING_CONDITION.profile}
              data={matchedData}
              isLoading={isLoadingMatchedFields}
            />
          </Tabs>
        ) : (
          <ScrollArea viewportClassName="h-full w-full rounded-[inherit] [&>*:first-child]:!block max-h-[calc(100vh_-_315px)] flex-1 overflow-y-auto px-6 pb-6">
            <div className="grid gap-y-5">
              {matchedFieldsList?.detail.map(
                (matchedField: MatchedFieldType, index: number) => (
                  <div key={`matched-field-detail-${index}`}>
                    <div className="mb-1.5 flex items-center gap-x-1.5">
                      <TypographyText className="text-sm font-medium text-gray-900">
                        {matchedField?.label}
                      </TypographyText>
                      {matchedField?.helpText &&
                        matchedField.field !== SYSTEM_JOB_FIELDS['salary'] && (
                          // https://linear.app/hireforce-ats/issue/HFX-914/ui-issue#comment-fa2a1e1f
                          <TypographyText className="text-sm text-gray-600">
                            ({matchedField?.helpText})
                          </TypographyText>
                        )}
                      {matchedField?.matched_rate && (
                        <TypographyText className="text-sm text-gray-600">
                          ({matchedField?.matched_rate})
                        </TypographyText>
                      )}
                    </div>
                    <div
                      className={cn(
                        'grid gap-x-4 gap-y-1.5',
                        matchedField?.data?.length > 3 ? 'grid-cols-2' : ''
                      )}>
                      {matchedField?.data?.map((field, index) => (
                        <div
                          key={`field-${index}`}
                          className="flex items-center gap-x-1.5">
                          {field.matched ? (
                            <CircleCheckFill
                              className={`flex-none !fill-green-500`}
                              size={20}
                            />
                          ) : (
                            <div className="mx-0.5 h-4 w-4 flex-none rounded-lg border border-gray-300" />
                          )}
                          <TypographyText className="flex-1 text-sm text-gray-700">
                            {field.label}
                          </TypographyText>
                        </div>
                      ))}
                    </div>
                  </div>
                )
              )}
            </div>
          </ScrollArea>
        )}

        <div className="flex flex-1 flex-row items-center p-6">
          <div className="flex flex-1 flex-row items-center">
            {modalMatchedRank?.job &&
            checkConditionShareJob?.recommendation?.shareBtn ? (
              <>
                <TextButton
                  underline={false}
                  size="md"
                  type="secondary"
                  onClick={() => {
                    setOpenShareModal(true)
                    if (!careerResponseSetting) triggerCareerSetting()
                  }}
                  iconMenus="LinkIcon"
                  label={`${t('button:shareJob')}`}
                />
              </>
            ) : null}
          </div>
          <div className="flex justify-end">
            <Button
              size="sm"
              label={`${t('button:close')}`}
              onClick={onCloseModal}
            />
          </div>
        </div>
        {user.currentTenant?.careerSiteSettings?.canonical_url &&
        modalMatchedRank?.job ? (
          <ShareJobModal
            shareInternal={checkConditionShareJob?.shareInternal}
            sharePublic={checkConditionShareJob?.sharePublic}
            urlReferral={`${PUBLIC_APP_URL}${pathConfiguration.careerHub.jobDetail(
              {
                tenantSlug: modalMatchedRank?.job?.tenant?.slug,
                jobId: modalMatchedRank?.job?.id.toString()
              }
            )}`}
            open={openShareModal}
            setOpen={setOpenShareModal}
            url={`${combineDomainCareerPage(
              careerResponseSetting
            )}${pathConfiguration?.careers.jobDetail({
              tenantSlug: modalMatchedRank?.job?.tenant?.slug,
              jobId: modalMatchedRank?.job?.slug
            })}`}
          />
        ) : null}
      </Dialog>
    </>
  )
}

export default MatchedRankDetail
